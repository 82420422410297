import { render, staticRenderFns } from "./carrier.svg?vue&type=template&id=c7d63ac4&"
var script = {}


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.10.1_css-loader@6.8.1_webpack@5.88.2__lodash@4.17.21_react-dom@18.3.1_react@18._owrbufnhxa6n2fhloml6ncbgju/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports