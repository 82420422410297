import { render, staticRenderFns } from "./PriceIllustration.vue?vue&type=template&id=2177b8d0&"
import script from "./PriceIllustration.vue?vue&type=script&lang=js&"
export * from "./PriceIllustration.vue?vue&type=script&lang=js&"
import style0 from "./PriceIllustration.vue?vue&type=style&index=0&id=2177b8d0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.10.1_css-loader@6.8.1_webpack@5.88.2__lodash@4.17.21_react-dom@18.3.1_react@18._owrbufnhxa6n2fhloml6ncbgju/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports